<!--banner section of register form-->
<div class="dxp-pr-0">
	<div *ngIf="message" class="message-container">
		<dxp-message severity="info">
			<dxp-message-text [text]="message"></dxp-message-text>
		</dxp-message>
	</div>
	<dxp-video-banner content-id="video-hero-banner" banner-type="hero" banner-size="extra-long" data-theme="dxp-theme-black" 
		eyebrowText="{{dxp.localeTranslator.translate('$.LOGIN.BANNER_EYEBROW')}}"  
		titleText="{{dxp.localeTranslator.translate('$.LOGIN.BANNER_TITLE')}}" 
		subtitle="{{dxp.localeTranslator.translate('$.LOGIN.BANNER_SUBTITLE')}}" 
		imageTitle="{{dxp.localeTranslator.translate('$.LOGIN.BANNER_ALT_TEXT')}}"
		alt="{{dxp.localeTranslator.translate('$.LOGIN.BANNER_ALT_TEXT')}}" 
		video-type="hostedExternally" src-video="https://mastercard-a.akamaihd.net/dxp/dxp-portal/DXP-home-video.mp4" 
		video-name="Marketing Technology Hub Home" video-description="Marketing Technology Hub Video" video-upload-date="07 Aug 2021" 
		disable-controls="true" custom-mute-button="false" custom-mute-button-position="top-right" muted="true" autoplay="true" 
		icon-type="light" responsive="true">
		<div class="dxp-portal-btn">
			<dxp-cta-list data-theme="dxp-theme-black">
				<dxp-button type="primary" class="login-button"
					ariaLabel="{{dxp.localeTranslator.translate('$.LOGIN.BANNER_LOGIN_BUTTON')}}" button-type="primary"
					text="{{dxp.localeTranslator.translate('$.LOGIN.BANNER_LOGIN_BUTTON')}}" id="signInModal"
					(click)="performLogout()" (ctaClickEvent)="loginModal($event)" aria-describedby="loginDescribedby">
				</dxp-button>
				<span id="loginDescribedby"
					class="dxp-sr-only">{{dxp.localeTranslator.translate('$.LOGIN_BANNER_LOGIN_BUTTON_INFO')}}</span>
				<dxp-link type="dxp-btn-internal-link"
					ariaLabel="{{dxp.localeTranslator.translate('$.LOGIN.BANNER_REGISTER_BUTTON_ARIA_LABEL')}}"
					aria-describedby="registerDescribedby"
					text="{{dxp.localeTranslator.translate('$.LOGIN.BANNER_REGISTER_BUTTON')}}"
					(ctaClickEvent)="loginModal($event)">
				</dxp-link>
				<span id="registerDescribedby"
					class="dxp-sr-only">{{dxp.localeTranslator.translate('$.LOGIN.BANNER_REGISTER_BUTTON_INFO')}}</span>
			</dxp-cta-list>
		</div>
	</dxp-video-banner>
</div>

<dxp-modal class="signin-model" width="600" footer-type="no-button" #modalLogin modal-id="login" close-on-outside-click="false"
	ariaLabel="{{dxp.localeTranslator.translate('$.LOGIN.MODEL_ACCESSIBILITY_TEXT')}}" (modalClose)="modalclosed()">
	<h3 *ngIf="forgotFlag" class="dxp-title-2 dxp-login-title">{{dxp.localeTranslator.translate('$.LOGIN.MODEL_TITLE')}}
	</h3>
	<p *ngIf="forgotFlag" class="dxp-error dxp-title-1" tabindex="0">{{errorMsg}}</p>
	<p class="dxp-error dxp-title-1" tabindex="0">{{passwordErrorMsg}}</p>
	<dxp-authentication *ngIf="modalFlag" active-page="signin" captcha-type="nuCaptcha" #dxpAuthentication>
		<dxp-login *ngIf="forgotFlag" (forgotLink)="forgotClicked()" slot="sign-in" [signInApi]="loginApiUrl" heading="" description=""
			usernameLabel="{{dxp.localeTranslator.translate('$.LOGIN.MODEL_USER_ID')}}" email-as-username=false
			[signOutApi]="logoutApiUrl"
			usernamePlaceholder="{{dxp.localeTranslator.translate('$.LOGIN.MODEL_USER_ID_PLACEHOLDER')}}"
			passwordLabel="{{dxp.localeTranslator.translate('$.LOGIN.MODEL_PASSWORD_LABEL')}}"
			forgotPassword="{{dxp.localeTranslator.translate('$.LOGIN.MODEL_FORGOT_PASSWORD_LABEL')}}"
			password-placeholder="*********" show-sign-up-link=true sign-up-link=""
			sign-up-text="$.LOGIN.MODEL_SIGN_UP_TEXT"
			sign-up-link-text="$.LOGIN.MODEL_SIGN_UP"
			buttonText="{{dxp.localeTranslator.translate('$.LOGIN.MODEL_BUTTON_TEXT')}}"
			api-headers-logout='{"Content-Type": "application/json","accept": "application/json"}'
			api-headers-login='{"Content-Type": "application/x-www-form-urlencoded","accept": "application/json"}'
			show-forgot-password-link=true sign-up-link="/register" open-in-new-tab=false
			ariaLabelForgotLink="{{dxp.localeTranslator.translate('$.LOGIN.ARIA_LABEL_FORGOT_LINK')}}">
		</dxp-login>
		<dxp-forgot-password   (signLinkEvent)="forgotClicked()" slot="forgot-password"
			[headingOne]="forgotModalTitle" email-as-username=false
			headingSuccess="{{dxp.localeTranslator.translate('$.FORGOT_MODEL.SECOND_TITLE')}}"
			descriptionSuccess="{{dxp.localeTranslator.translate('$.FORGOT_MODEL.DESCRIPTION')}}"
			label="{{dxp.localeTranslator.translate('$.LOGIN.MODEL_USER_ID')}}"
			placeholder="{{dxp.localeTranslator.translate('$.LOGIN.MODEL_USER_ID_PLACEHOLDER')}}"
			buttonTextOne="{{dxp.localeTranslator.translate('$.FORGOT_MODEL.SEND_EMAIL')}}"
			buttonTextThree="{{dxp.localeTranslator.translate('$.FORGOT_MODEL.SIGNIN_TEXT')}}" show-sign-in-link=true
			open-in-new-tab=false signInText="{{dxp.localeTranslator.translate('$.FORGOT_MODEL.PASSWORD_TEXT')}}"
			signInLinkText="{{dxp.localeTranslator.translate('$.REGISTRATION.SIGN_IN')}}"
			[forgotPasswordApi]="forgotPasswordApi" use-captcha="true"
			apiFailMessage="{{dxp.localeTranslator.translate('$.FORGOT_MODEL.API_ERROR)}}"
			captchaErrorText="{{dxp.localeTranslator.translate('$.FORGOT_MODEL.CAPTCHA_ERROR')}}"
			[nuCaptchaBaseUrl]="nuCaptchaBaseUrl" 
			[nuCaptchaClientId]="nuCaptchaClientId" 
			[testMode]="testMode"
			ariaLabelSignin="{{dxp.localeTranslator.translate('$.FORGOT_MODEL.ARIA_LABEL_SIGNIN')}}">
		</dxp-forgot-password>
	</dxp-authentication>
</dxp-modal>
<dxp-audio data-designmode="dxp-audio" src="https://asset.mastercard.com/content/dam/dxp/media/MastercardSoundscape.mp3"
	type="mpeg" preload="auto" position="top-right" loop="true" controls="false" playpause="false" autoplay="false"
	muted="false" playpauseLabel="{{dxp.localeTranslator.translate('$.LOGIN.AUDIO_PLAY')}}"
	muteunmuteLabel="{{dxp.localeTranslator.translate('$.LOGIN.AUDIO_MUTE')}}">
</dxp-audio>