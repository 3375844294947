import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ResetPasswordDataService {

  constructor(
    private readonly http: HttpClient
  ) { }

  getResetPasswordSchema(): Observable<any> {
    return this.http.get(environment.DXP_PORTAL_RESET_PASSWORD_SCHEMA);
  }
}
