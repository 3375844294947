const STAGE_API_URL = 'https://stage.mydxp.mastercard.com';
export const environment = {
  production: true,
  BASE_HREF: '/dxp/ui/public',
  DXP_PORTAL_LOGIN_API: `${STAGE_API_URL}/heracles/login`,
  DXP_PORTAL_LOGOUT_API: `${STAGE_API_URL}/heracles/logout`,
  DXP_PORTAL_USER_ID_API: `${STAGE_API_URL}/dxpservices/public/mydxp-apis/open-users`,
  DXP_PORTAL_RESET_PASSWORD_API: `${STAGE_API_URL}/secured-authapis/mydxp-auth-apis/users/reset-password`,
  DXP_REQUEST_FORM_PROGRAM_OWNER_URL: `${STAGE_API_URL}/public-apis/mydxp-apis/open-technical-assets?search=`,
  DXP_PORTAL_RESET_PASSWORD_SCHEMA: `${STAGE_API_URL}/dxp/ui/public/assets/schema-json/reset-pass.json`,
  DXP_PORTAL_FORGOT_PASSWORD_API: `${STAGE_API_URL}/public-authapis/mydxp-auth-apis/users/forgotpassword`,
  DXP_PORTAL_SIGN_UP_API_INTERNAL: `${STAGE_API_URL}/public-authapis/mydxp-auth-apis/registration-requests/internal-users`,
  DXP_PORTAL_SIGN_UP_API_EXTERNAL: `${STAGE_API_URL}/public-authapis/mydxp-auth-apis/registration-requests/external-users`,
  DXP_PORTAL_ARITHMETIC_GENERATE_API: `${STAGE_API_URL}/dxp/captcha/generate`,
  DXP_PORTAL_ARITHMETIC_VALIDATE_API: `${STAGE_API_URL}/dxp/captcha/validate`,
  DXP_PORTAL_USER_GUIDE: `${STAGE_API_URL}/content/dam/public/dxpportal/DXP_Portal_Login_Guide.pdf`,
  DXP_PORTAL_PUBLIC: '/dxp/ui/public/',
  DXP_PORTAL_SECURED: '/dxp/ui/secured/',
  CONSENT: [
    {
      type: 'termsandconditions',
      link: `${STAGE_API_URL}/consent/language/dxp/locale/reg/tc`,
    },
    {
      type: 'termsandconditions',
      link: `${STAGE_API_URL}/consent/legalcontent/dxp/locale/reg/tc`,
    },
    {
      type: 'privacynotice',
      link: `${STAGE_API_URL}/consent/language/dxp/locale/reg/pn`,
    },
    {
      type: 'privacynotice',
      link: `${STAGE_API_URL}/consent/legalcontent/dxp/locale/reg/pn`,
    },
  ],
  DXP_PORTAL_I18N: `${STAGE_API_URL}/dxp/ui/public/assets/i18n/resourcebundle.json`,
  DXP_PORTAL_EMAIL_DOMAIN: `${STAGE_API_URL}/dxp/ui/public/assets/schema-json/email-domain.json`,
  DXP_PORTAL_NUCAPTCHA_CLIENT_ID:'w-378419',
  DXP_PORTAL_NUCAPTCHA_BASE_URL:'https://api-mastercard-dxp.nd.nudatasecurity.com/2.2/w/',
  DXP_PORTAL_NUCAPTCHA_TEST_MODE:'true'
};
