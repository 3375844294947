import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ConsentService {
  consentLanguageData = [];
  consentLanguage = environment.CONSENT;
  applicationLanguage = '';
  consentData: any;
  constructor(private readonly http: HttpClient) {
    this.getLanguage();
  }

  async fetchConsentLanguage() {
    this.consentLanguageData = [];
    for (const item of this.consentLanguage) {
      const link = item.link.replace('locale', this.applicationLanguage);
      this.consentLanguageData.push(await this.fetchData(link));
    }
    return this.consentLanguageData;
  }

  fetchData(_link) {
    return this.http.get(_link).toPromise();
 }

  getLanguage() {
    // temp solution for locale
    this.applicationLanguage = (
      document.querySelector('html').lang || window.navigator.language
    ).toLowerCase();
  }
}
