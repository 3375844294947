import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
} from '@angular/forms';
import { sortBy } from 'lodash';
import { environment } from '../../environments/environment';
import { CommonService } from '../shared/common.service';
import { ConsentService } from '../consent/consent.service';
import { Router } from '@angular/router';
import { RegisterService } from './register.service';
import { Consent } from './consent.interface';
import dxp from '@mc-dxp/dxp-ui-core';
const DEFAULT_PLACEMENT = 'UserRegistration';
@Component({
  selector: 'dxp-portal-user-register',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss'],
})
export class RegisterUserComponent implements OnInit, AfterViewInit {
  @Output() backClicked = new EventEmitter();
  @ViewChild('radioButton', { read: ElementRef }) focusElement: ElementRef;
  @ViewChild('nuCaptchaRef', { read: ElementRef }) nuCaptchaComponent: ElementRef;
  userType = '';
  userID = '';
  programOwnerUrl = environment.DXP_REQUEST_FORM_PROGRAM_OWNER_URL;
  programOwnerData: any;
  newRegistration = false;
  userRegistered = false;
  successfullyRegistered = false;
  consentChecked = false;
  currentDate = '';
  preparedConsents = [];
  consentData = [];
  dxp = dxp;
  user = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    companyName: '',
    technicalAssetId: '',
    businessJustification: '',
    consents: [],
    identifier: '',
    userType: '',
    nuCaptchaScoreDto: {
      transactionParams: {
        placement: '',
        reportSegment: '',
        placementPage: 1,
      },
      captchaValidate: {
        nuCaptchaToken: '',
        nuCaptchaAnswer: ''
      },
      widgetData: '',
      actionType: ''
    }
  };
  placement = DEFAULT_PLACEMENT;
  clientID = environment.DXP_PORTAL_NUCAPTCHA_CLIENT_ID;
  baseURL = environment.DXP_PORTAL_NUCAPTCHA_BASE_URL;
  showLoadingIndicator = false;
  registrationForm: FormGroup;
  showError: any;
  infoText = '';
  registrationType = '';
  actionType = 'SCORE';
  captchaDetails: any = {
    transactionParams: {
      placement: 'UserRegistration',
      placementPage: 1,
      reportSegment: 'User Registration'
    },
  };
  consent: Consent = {
    uuid: '',
    consentedDate: '',
    lastModifiedDate: '',
    serviceCode: '',
    serviceName: '',
    serviceFunctionCode: '',
    useCategoryCode: '',
    currentVersion: '',
    locale: '',
    agreed: false,
    consentType: '',
    required: true,
  };

  constructor(
    private readonly fb: FormBuilder,
    private readonly regService: RegisterService,
    private readonly sharedService: CommonService,
    public consentService: ConsentService,
    public router: Router,
    public postRegistration: ElementRef
  ) {}

  ngOnInit() {
    this.prepareForm();
    this.prepareProgramOwnerData();
  }

  ngAfterViewInit() {
    this.focusElement.nativeElement.focus();
    const headerElement: Element =
      document.getElementsByTagName('dxp-header-rich')[0];
    if (headerElement) {
      headerElement.setAttribute('background-type', 'solid');
    }
  }

  /** to get the program details */
  getProgramDetails(event) {
    this.user.technicalAssetId = this.programOwnerData.filter(
      (items) => event.detail.optionSelected.value[0] === items.name
    )[0].technicalAssetId;
  }

  registerUser(): void {
    this.user = {
      firstName: this.registrationForm.value.firstName.trim(),
      lastName: this.registrationForm.value.lastName.trim(),
      userName: this.registrationForm.value.userName.trim(),
      email: this.registrationForm.value.email
        ? this.registrationForm.value.email.trim()
        : null,
      companyName: this.registrationForm.value.companyName
        ? this.registrationForm.value.companyName.trim()
        : '',
      technicalAssetId: this.user.technicalAssetId,
      businessJustification: this.registrationForm.value.businessJustification
        ? this.registrationForm.value.businessJustification.trim()
        : '',
      identifier: 'a',
      userType: this.registrationType.toUpperCase(),
      consents: this.preparedConsents,
      nuCaptchaScoreDto: {
        widgetData: this.captchaDetails.widgetData,
        captchaValidate: this.captchaDetails.captchaValidate,
        transactionParams: this.captchaDetails.transactionParams,
        actionType: this.actionType
      }
   };
  }

  prepareConsentObj(ev) {
    if (ev.detail.isChecked) {
      this.currentDate = new Date().toJSON();
      for (const item of this.consentService.consentData) {
        if (
          ev.detail.value === item.consentUseData[0].useCategory.useCategoryCode
        ) {
          this.consent = {
            uuid: item.uuid,
            consentedDate: this.currentDate,
            serviceCode: item.serviceCode,
            serviceName: item.serviceName,
            serviceFunctionCode: item.serviceFunctionCode,
            lastModifiedDate: '',
            useCategoryCode: item.consentUseData[0].useCategory.useCategoryCode,
            currentVersion: item.currentVersion,
            locale: item.locale,
            agreed: true,
            consentType: item.consentUseData[0].consentData.documenttype,
            required: true,
          };
          this.preparedConsents.push(this.consent);
        }
      }
      this.consentChecked = this.preparedConsents.length === 4 ? true : false;
    } else {
      this.preparedConsents = this.preparedConsents.filter((ele) =>
        ev.detail.value === ele.useCategoryCode ? false : true
      );
      this.consentChecked = false;
    }
  }

  /** to transform the data from the api */
  prepareProgramOwnerData() {
    this.sharedService.getProgramDetail().subscribe((response) => {
      this.programOwnerData = response.data
        .map((items) => ({
          ownerName: items.ownerName,
          name: items.name,
          technicalAssetId: items.id,
          value: [items.name],
        }));
        this.programOwnerData = sortBy(this.programOwnerData, 'name', 'asc');
    });
  }

  async validatePrimaryDetails() {
    if (this.registrationForm.invalid) {
      return;
    }
    this.captchaDetails.widgetData = await this.nuCaptchaComponent.nativeElement.getWidgetData();
    this.captchaDetails.captchaValidate = await this.nuCaptchaComponent.nativeElement.getTokenAnswer();
    this.registerUser();
    this.showLoadingIndicator = true;
    this.regService
      .registerUser(this.user, this.registrationType)
      .subscribe(
        (response) => {
          this.successfullyRegistered = response;
          this.userRegistered = true;
          this.showLoadingIndicator = false;
          this.nuCaptchaComponent.nativeElement.removeCaptcha();
        },
        (error) => {
          this.showError = error.error?.Errors.Error[0].Description || error;
          this.userRegistered = false;
          this.showLoadingIndicator = false;
          if (['Captcha required','Invalid captcha'].includes(error.error?.Errors.Error[0].ReasonCode)) {
            const captchaHtml = error.error.Errors.Error[0].Details;
            this.nuCaptchaComponent.nativeElement.renderCaptcha(captchaHtml);
            this.actionType = 'VALIDATE';
          }
          setTimeout(() => {
            const errorMessage =
              this.postRegistration.nativeElement.querySelector('.dxp-error');
            errorMessage.focus();
          });
        }
      )
      .add(() => {
        this.sharedService.scrollToTop();
      });
  }

  goBackToRegister(ev) {
    this.backClicked.emit(ev);
  }
  goBackToLogin() {
    this.sharedService.navigatedFromRegistration = true;
    this.router.navigate(['/login']);
  }

  get personalDetailsFormControls(): any {
    return this.registrationForm.controls;
  }

  clearSelectedValue() {
    this.user.technicalAssetId = '';
  }
  emailDomain(domainNames: string[]) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.value;
      const domain = email.substring(email.lastIndexOf('@') + 1);

      if (email !== '' && domainNames.includes(domain.toLowerCase())) {
        return { emailDomain: true };
      } else {
        return null;
      }
    };
  }
  getSelectedUser(ev) {
    this.newRegistration = ev.detail.value === 'external' ? true : false;
    this.showError = false;
    this.prepareForm();
  }
  prepareForm() {
    if (this.newRegistration) {
      this.registrationForm = this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        userName: ['', Validators.required],
        email: [
          '',
          [
            Validators.required,
            Validators.email,
            this.emailDomain(this.sharedService.emailList),
          ],
        ],
        companyName: ['', Validators.required],
        businessJustification: ['', Validators.required],
      });
      this.infoText = this.dxp.localeTranslator.translate(
        '$.REGISTRATION.EXTERNAL_USER_INFO'
      );
      this.registrationType = 'external';
    } else {
      this.registrationForm = this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        userName: ['', Validators.required],
      });
      this.infoText = this.dxp.localeTranslator.translate(
        '$.REGISTRATION.INTERNAL_USER_INFO'
      );
      this.registrationType = 'internal';
    }
  }
}
