import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const APPLICATION_TYPE = 'application/json';
const NUCAPTCHA_SESSION_ID = 'nuCaptchaSessionId';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(private readonly http: HttpClient) {}

  registerUser(userData, registrationType): Observable<any> {
    let url = '';
    if (registrationType === 'internal') {
      url = environment.DXP_PORTAL_SIGN_UP_API_INTERNAL;
    } else {
      url = environment.DXP_PORTAL_SIGN_UP_API_EXTERNAL;
    }
    return this.http.post(url, userData, {
      headers: new HttpHeaders({
        'Content-Type': APPLICATION_TYPE,
        sessionId: sessionStorage.getItem(NUCAPTCHA_SESSION_ID),
        testMode: environment.DXP_PORTAL_NUCAPTCHA_TEST_MODE
      })
    });
  }
}
