import { Component } from '@angular/core';
import dxp from '@mc-dxp/dxp-ui-core';
import { environment } from '../../environments/environment';
@Component({
  selector: 'dxp-portal-public-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  dxp = dxp;
  userGuide = environment.DXP_PORTAL_USER_GUIDE;
  constructor() {
    // do nothing.
  }

  openUserGuide(): void {
    window.open(this.userGuide, '_blank');
  }
}
