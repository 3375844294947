<div class="reset-container dxp-row dxp-theme-white">
  <div class="dxp-col-sm-6 dxp-col-12 reset-form">
    <div *ngIf="pwdSchema && showMsg" class="message-container">
      <dxp-message [severity]="severity" close-button-title="Close message!">
        <dxp-message-text [text]="message"></dxp-message-text>
      </dxp-message>
    </div>
    <dxp-authentication active-page="reset-password">
      <dxp-reset-password slot="reset-password" id="reset-password" headingOne="{{dxp.localeTranslator.translate('$.RESETPASSWORD.TITLE')}}"
        [passwordData]="pwdSchema" [userId]="userId"
        passwordError="{{dxp.localeTranslator.translate('$.RESETPASSWORD.ERROR')}}"
        buttonTextOne="{{dxp.localeTranslator.translate('$.REGISTRATION.SUBMIT')}}"
        ariaLabelReset="{{dxp.localeTranslator.translate('$.RESETPASSWORD.ARIA_TEXT')}}"
        currentPasswordError="{{dxp.localeTranslator.translate('$.RESETPASSWORD.ERROR_OLD')}}"
        api-headers='{"Content-Type": "application/json","accept": "application/json"}'
        [resetPasswordApi]=resetPasswordApiUrl button-text-two="" sign-in-link="/">
      </dxp-reset-password>
    </dxp-authentication>
  </div>
  <!--right section of request form-->
  <div class="dxp-col-sm-6 dxp-col-12 md-image-container">
    <dxp-image src="https://asset.mastercard.com/content/dam/dxp/dxp-portal/assets/images/circle-with-text.svg" responsive="true"
      alt="Marketing Technology Hub. Welcome to the world of endless possibilities.">
    </dxp-image>
  </div>
</div>