import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConsentService } from './consent/consent.service';
import dxp from '@mc-dxp/dxp-ui-core';
import { environment } from './../environments/environment';
import { CommonService } from './shared/common.service';
import resourceBundle from '../assets/i18n/resourcebundle.json';

@Component({
  selector: 'dxp-portal',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'dxp-portal-public';
  constructor(
    private readonly router: Router,
    private readonly zone: NgZone,
    private sharedService: CommonService,
    public consentService: ConsentService
  ) {}

  ngOnInit(): void {
    this.initDXPWebRoutingPublicApp();
    this.prepareConsentData();
    this.prepareLocalization();
    this.getEmailList();
  }

  initDXPWebRoutingPublicApp(): void {
    // global routing method required by DXP components to trigger consumer app routing
    Object.assign(window, {
      navigateByRouting: (href: string) => {
        if (href) {
          // replace starting # (if any used for # based routing) then split for separating out query
          const routeSegments = href.replace(/^#/, '').split('?');
          const path = routeSegments[0];
          const queryString = routeSegments[1]
            ? `?${routeSegments[1]}`.trim()
            : null;
          const queryParams = {};
          if (queryString) {
            const searchParams = new URLSearchParams(queryString);
            searchParams.forEach((value, key) => {
              Object.assign(queryParams, { [key]: value });
            });
          }
          // we need NgZone.run as routing is triggered from outside of Angular's scope
          this.zone.run(() => {
            if(path){
              this.router.navigate([path], { queryParams });
             }
          });
        }
      },
    });
  }


  prepareConsentData() {
    this.consentService.fetchConsentLanguage().then((data) => {
      this.consentService.consentData = data;
    });
  }

  getEmailList() {
    this.sharedService.getEmailDomain().subscribe(
      data => {
        this.sharedService.emailList = data.emails;
      }
    );
  }

  prepareLocalization() {
    if (environment.production) {
      dxp.localeTranslator.setResourceBundle(
        'API',
        environment.DXP_PORTAL_I18N
      );
    } else {
      dxp.localeTranslator.setResourceBundle(
        'JSON',
        resourceBundle
      );
    }
    dxp.config.set('lang', navigator.language);
    dxp.localeTranslator.setFallbackLanguage('en');
    dxp.localeTranslator.setLanguageDetectorOrder([
      dxp.localeTranslator.languageDetectorOptions.DXPCONFIG,
      dxp.localeTranslator.languageDetectorOptions.HTMLTAG,
      dxp.localeTranslator.languageDetectorOptions.METATAG,
      dxp.localeTranslator.languageDetectorOptions.PATH,
      dxp.localeTranslator.languageDetectorOptions.QUERYSTRING,
      dxp.localeTranslator.languageDetectorOptions.COOKIES,
      dxp.localeTranslator.languageDetectorOptions.LOCALSTORAGE,
    ]);

    dxp.localeTranslator.setLookupDxpConfig('lang');
    dxp.localeTranslator.setLookupFromPathIndex(0);
  }
}
