import {
  HttpErrorResponse,
  HttpClient,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  navigatedFromRegistration = false;
  emailList;
  id = '';

  constructor(
    private readonly http: HttpClient,
    public route: ActivatedRoute
  ) { }

  /** handle service failure errors */
  handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(
      Array.isArray(error.error.Errors.Error)
        ? error.error.Errors.Error[0].Description
        : error.error.Errors.Error.Description
    );
  }

  /** Get the Program details data */
  getProgramDetail(): Observable<any> {
    let params = new HttpParams();
    params = params.append('show', 'TRUE');
    return this.http.get(environment.DXP_REQUEST_FORM_PROGRAM_OWNER_URL, { params });
  }
  /** setter for id */
  setId(id) {
    this.id = id;
  }
  /** getter for id */
  getId() {
    return this.id;
  }

  /** Get email domain list */
  getEmailDomain(): Observable<any> {
    return this.http.get(environment.DXP_PORTAL_EMAIL_DOMAIN);
  }

  /** perform logout */
  performLogout(): Observable<any> {
    return this.http.get(environment.DXP_PORTAL_LOGOUT_API, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  /** perform login */
  performLogIn(userAlias, password): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      accept: 'application/json',
    });
    const formData = new URLSearchParams();
    formData.append('username', userAlias);
    formData.append('password', password);
    formData.append('login-form-type', 'password');
    return this.http.post(environment.DXP_PORTAL_LOGIN_API, formData.toString(), { headers }) .pipe(
      catchError(this.handleError)
    );
  }

  getErrorCode() {
    return this.route.snapshot.queryParamMap.get('text')
      ? decodeURIComponent(this.route.snapshot.queryParamMap.get('text'))
      : '';
  }

  navigateByUrl(url, newtab = '_self') {
    window.open(decodeURIComponent(url), newtab);
  }

  scrollToTop(): void {
    window.scrollTo(0, 0);
  }
}
