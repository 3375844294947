<div class="register-container dxp dxp-row dxp-theme-white">
  <div class="dxp-col-sm-6 dxp-col-12 register-form">
    <div *ngIf="!userRegistered; else post_registration" #postRegistration>
      <dxp-radio-group (radioSelected)="getSelectedUser($event)" alignment="horizontal">
        <dxp-radio-button #radioButton
          label="<img alt='{{dxp.localeTranslator.translate('$.REGISTRATION.MASTERCARD_USER')}}' src='https://asset.mastercard.com/content/dam/dxp/dxp-portal/assets/images/icons/mastercard-user-icon.svg'></img> {{dxp.localeTranslator.translate('$.REGISTRATION.MASTERCARD_USER')}}"
          radio-key="internal" name="internal" value="internal" checked="true">
        </dxp-radio-button>
        <dxp-radio-button
          label="<img alt='{{dxp.localeTranslator.translate('$.REGISTRATION.EXTERNAL_USER')}}' src='https://asset.mastercard.com/content/dam/dxp/dxp-portal/assets/images/icons/external_user-icon.svg'></img> {{dxp.localeTranslator.translate('$.REGISTRATION.EXTERNAL_USER')}}"
          radio-key="external" name="external" value="external" checked="false">
        </dxp-radio-button>
      </dxp-radio-group>
      <p *ngIf="showError" class="dxp-error dxp-title-1" tabindex="0">{{ showError }}</p>
      <h3 class="legend">
        {{ dxp.localeTranslator.translate("$.REGISTRATION.REGISTER") }}
      </h3>
      <form [formGroup]="registrationForm" (ngSubmit)="validatePrimaryDetails()">
        <div class="dxp-input dxp-mb-4" data-theme="dxp-theme-white">
          <label for="firstName" class="dxp-inputbox-label dxp-font-size-sm" [ngClass]="{
              'dxp-error':
                personalDetailsFormControls.firstName.invalid &&
                (personalDetailsFormControls.firstName.dirty ||
                  personalDetailsFormControls.firstName.touched)
            }">
            {{
            dxp.localeTranslator.translate("$.REGISTRATION.FIRST_NAME_LABEL")
            }}
          </label>
          <input id="firstName" type="text" aria-required="true" formControlName="firstName" attr.aria-label="{{
              dxp.localeTranslator.translate(
                '$.REGISTRATION.REGISTER_FIRST_NAME_PLACEHOLDER'
              )
            }}" placeholder="{{
              dxp.localeTranslator.translate(
                '$.REGISTRATION.REGISTER_FIRST_NAME_PLACEHOLDER'
              )
            }}" maxlength="50" attr.aria-describedby="{{ personalDetailsFormControls.firstName.invalid &&
              (personalDetailsFormControls.firstName.dirty ||
                personalDetailsFormControls.firstName.touched) ? 'firstNameError' : undefined }}" #firstname />
          <div *ngIf="
              personalDetailsFormControls.firstName.invalid &&
              (personalDetailsFormControls.firstName.dirty ||
                personalDetailsFormControls.firstName.touched)
            " class="dxp-error">
            <div id="firstNameError" *ngIf="personalDetailsFormControls.firstName.errors.required"
              aria-live="assertive">
              {{
              dxp.localeTranslator.translate("$.REGISTRATION.FIRST_NAME_REQUIRED")
              }}
            </div>
          </div>
        </div>

        <div class="dxp-input dxp-mb-4" data-theme="dxp-theme-white">
          <label for="lastName" class="dxp-inputbox-label dxp-font-size-sm" [ngClass]="{
              'dxp-error':
                personalDetailsFormControls.lastName.invalid &&
                (personalDetailsFormControls.lastName.dirty ||
                  personalDetailsFormControls.lastName.touched)
            }">
            {{dxp.localeTranslator.translate("$.REGISTRATION.LAST_NAME_LABEL")}}
          </label>
          <input id="lastName" type="text" aria-required="true" formControlName="lastName" attr.aria-label="{{
              dxp.localeTranslator.translate('$.REGISTRATION.REGISTER_LAST_NAME_PLACEHOLDER')
            }}" placeholder="{{
              dxp.localeTranslator.translate('$.REGISTRATION.REGISTER_LAST_NAME_PLACEHOLDER')
            }}" maxlength="50" attr.aria-describedby="{{ personalDetailsFormControls.lastName.invalid &&
              (personalDetailsFormControls.lastName.dirty ||
                personalDetailsFormControls.lastName.touched) ? 'lastNameError' : undefined }}" />
          <div *ngIf="
              personalDetailsFormControls.lastName.invalid &&
              (personalDetailsFormControls.lastName.dirty ||
                personalDetailsFormControls.lastName.touched)
            " class="dxp-error">
            <div id="lastNameError" *ngIf="personalDetailsFormControls.lastName.errors.required" aria-live="assertive">
              {{dxp.localeTranslator.translate("$.REGISTRATION.LAST_NAME_REQUIRED")}}
            </div>
          </div>
        </div>

        <div class="dxp-input dxp-mb-4 user-id-container" data-theme="dxp-theme-white">
          <dxp-tooltip selector-id="tooltip-selector" trigger="mouseover,mouseout" placement="right"
            [content]="infoText">
          </dxp-tooltip>
          <label for="userName" class="dxp-inputbox-label dxp-font-size-sm" [ngClass]="{
              'dxp-error':
                personalDetailsFormControls.userName.invalid &&
                (personalDetailsFormControls.userName.dirty ||
                  personalDetailsFormControls.userName.touched)
            }">
            {{dxp.localeTranslator.translate("$.REGISTRATION.USER_ID")}}
          </label>
          <em class="dxp-icons dxp-i-info dxp-i-circle dxp-i-1x" id="tooltip-selector"></em>
          <input id="userName" type="text" aria-required="true" formControlName="userName" attr.aria-label="{{
              newRegistration
                ? (dxp.localeTranslator.translate('$.REGISTRATION.REGISTER_EXTERNAL_USER_PLACEHOLDER'))
                : (dxp.localeTranslator.translate('$.REGISTRATION.REGISTER_INTERNAL_USER_PLACEHOLDER'))
            }}" placeholder="{{
              newRegistration
                ? (dxp.localeTranslator.translate('$.REGISTRATION.REGISTER_EXTERNAL_USER_PLACEHOLDER'))
                : (dxp.localeTranslator.translate('$.REGISTRATION.REGISTER_INTERNAL_USER_PLACEHOLDER'))
            }}" maxlength="100" attr.aria-describedby="{{personalDetailsFormControls.userName.invalid &&
              (personalDetailsFormControls.userName.dirty ||
                personalDetailsFormControls.userName.touched) ? 'userNameError' : undefined}}" />
          <div *ngIf="
              personalDetailsFormControls.userName.invalid &&
              (personalDetailsFormControls.userName.dirty ||
                personalDetailsFormControls.userName.touched)
            " class="dxp-error">
            <div id="userNameError" *ngIf="personalDetailsFormControls.userName.errors.required" aria-live="assertive">
              {{dxp.localeTranslator.translate("$.REGISTRATION.USER_ID_REQUIRED")}}
            </div>
          </div>
        </div>
        <div *ngIf="newRegistration && personalDetailsFormControls?.email" class="dxp-input dxp-mb-4"
          data-theme="dxp-theme-white">
          <label for="email" class="dxp-inputbox-label dxp-font-size-sm" [ngClass]="{
              'dxp-error':
                personalDetailsFormControls.email.invalid &&
                (personalDetailsFormControls.email.dirty ||
                  personalDetailsFormControls.email.touched)
            }">
            {{dxp.localeTranslator.translate("$.REGISTRATION.WORK_EMAIL")}}
          </label>
          <input id="email" type="email" aria-required="true" formControlName="email" attr.aria-label="{{
              dxp.localeTranslator.translate('$.REGISTRATION.REGISTER_EMAIL_PLACEHOLDER')
            }}" placeholder="{{
              dxp.localeTranslator.translate('$.REGISTRATION.REGISTER_EMAIL_PLACEHOLDER')
            }}" attr.aria-describedby="{{personalDetailsFormControls.email.invalid &&
              (personalDetailsFormControls.email.dirty ||
                personalDetailsFormControls.email.touched) ? 'emailError' : undefined}}" />
          <div *ngIf="
              personalDetailsFormControls.email.invalid &&
              (personalDetailsFormControls.email.dirty ||
                personalDetailsFormControls.email.touched)
            " class="dxp-error">
            <div id="emailError" *ngIf="personalDetailsFormControls.email.errors.required" aria-live="assertive">
              {{dxp.localeTranslator.translate("$.REGISTRATION.EMAIL_REQUIRED")}}
            </div>
            <div id="emailError" *ngIf="personalDetailsFormControls.email.errors.email" aria-live="assertive">
              {{dxp.localeTranslator.translate("$.REGISTRATION.VALID_EMAIL")}}
            </div>
            <div id="emailError" *ngIf="personalDetailsFormControls.email.errors.emailDomain" aria-live="assertive">
              {{dxp.localeTranslator.translate("$.REGISTRATION.VALID_EMAIL")}}
            </div>
          </div>
        </div>
        <dxp-progressspinner *ngIf="showLoadingIndicator" class="dxp-loader" data-theme="custom-theme" type="logo">
        </dxp-progressspinner>
        <div *ngIf="newRegistration" class="dxp-input dxp-mb-4" data-theme="dxp-theme-white">
          <div class="dxp-mb-4">
            <dxp-selector-menu type="SearchableSelector" data-theme="dxp-theme-white" [items]="programOwnerData"
              class="request-form-field" validationMessage="{{
                dxp.localeTranslator.translate('$.REGISTRATION.NO_RECORDS_FOUND')
              }}" accessibility-text="{{
                dxp.localeTranslator.translate('$.REGISTRATION.PROGRAM_NAME_ARIA_LABEL')
              }}" (clearValue)="clearSelectedValue()" placeholder="{{
                dxp.localeTranslator.translate('$.REGISTRATION.PROGRAM_NAME_PLACEHOLDER')
              }}" lazyload="false" minlength="4" offset-key-name="offset" limit-key-name="limit" maxlength="100"
              limit="50" data-source-key-name="value" total-pages-key-name="count"
              label="{{dxp.localeTranslator.translate('$.REGISTRATION.PROGRAM_NAME')}}"
              (selectedItemChanged)="getProgramDetails($event)">
            </dxp-selector-menu>
          </div>
          <div class="dxp-input dxp-mb-4" data-theme="dxp-theme-white">
            <label for="companyName" class="dxp-inputbox-label dxp-font-size-sm" [ngClass]="{
                'dxp-error':
                  personalDetailsFormControls.companyName.invalid &&
                  (personalDetailsFormControls.companyName.dirty ||
                    personalDetailsFormControls.companyName.touched)
              }">
              {{dxp.localeTranslator.translate("$.REGISTRATION.COMPANY_NAME")}}
            </label>
            <input id="companyName" type="text" aria-required="true" formControlName="companyName" attr.aria-label="{{
                dxp.localeTranslator.translate('$.REGISTRATION.REGISTER_COMPANY_PLACEHOLDER')
              }}" placeholder="{{
                dxp.localeTranslator.translate('$.REGISTRATION.REGISTER_COMPANY_PLACEHOLDER')
              }}" maxlength="100" attr.aria-describedby="{{personalDetailsFormControls.companyName.invalid &&
                (personalDetailsFormControls.companyName.dirty ||
                  personalDetailsFormControls.companyName.touched) ? 'companyNameError' : undefined}}" />
            <div *ngIf="
                personalDetailsFormControls.companyName.invalid &&
                (personalDetailsFormControls.companyName.dirty ||
                  personalDetailsFormControls.companyName.touched)
              " class="dxp-error">
              <div id="companyNameError" *ngIf="personalDetailsFormControls.companyName.errors.required"
                aria-live="assertive">
                {{dxp.localeTranslator.translate("$.REGISTRATION.COMPANY_REQUIRED")}}
              </div>
            </div>
          </div>
          <div class="dxp-input dxp-mb-4" data-theme="dxp-theme-white">
            <label for="businessJustification" class="dxp-inputbox-label dxp-font-size-sm" [ngClass]="{
                'dxp-error':
                  personalDetailsFormControls.businessJustification.invalid &&
                  (personalDetailsFormControls.businessJustification.dirty ||
                    personalDetailsFormControls.businessJustification.touched)
              }">
              {{dxp.localeTranslator.translate("$.REGISTRATION.JUSTIFICATION")}}
            </label>
            <input id="businessJustification" type="text" aria-required="true" formControlName="businessJustification"
              attr.aria-label="{{
                dxp.localeTranslator.translate('$.REGISTRATION.REGISTER_JUSTIFICATION_PLACEHOLDER')
              }}" placeholder="{{
                dxp.localeTranslator.translate('$.REGISTRATION.REGISTER_JUSTIFICATION_PLACEHOLDER')
              }}" maxlength="100" aria-describedby="businessJustificationError" />
            <div *ngIf="
                personalDetailsFormControls.businessJustification.invalid &&
                (personalDetailsFormControls.businessJustification.dirty ||
                  personalDetailsFormControls.businessJustification.touched)
              " class="dxp-error">
              <div id="businessJustificationError" *ngIf="
                  personalDetailsFormControls.businessJustification.errors
                    .required
                " aria-live="assertive">
                {{dxp.localeTranslator.translate("$.REGISTRATION.JUSTIFICATION_REQUIRED")}}
              </div>
            </div>
          </div>
        </div>
        <div class="dxp-mb-4" data-theme="dxp-theme-white">
          <dxp-nucaptcha #nuCaptchaRef [clientId]="clientID" [placement]="placement"
            [baseUrl]="baseURL"
            captcha-label="captcha"></dxp-nucaptcha>
        </div>
        <div class="dxp-mt-5">
          <dxp-checkbox-group select-all="false" alignment="horizontal" separator="false" required="false">
            <div *ngFor="let product of consentService.consentData">
              <dxp-checkbox *ngIf="
                  product.consentUseData[0].consentData.documenttype ===
                  'consentlanguagetext'
                " (checkboxData)="prepareConsentObj($event)" required="false"
                label="{{ product.consentUseData[0].consentData.data }}" value="{{
                  product.consentUseData[0].useCategory.useCategoryCode
                }}" alignment="vertical"></dxp-checkbox>
            </div>
          </dxp-checkbox-group>
        </div>
        <div class="nav-link">
          <dxp-button *ngIf="!newRegistration" text="{{dxp.localeTranslator.translate('$.REGISTRATION.CONTINUE')}}" [state]="
              registrationForm.valid &&
              consentChecked &&
              !showLoadingIndicator
                ? 'enabled'
                : 'disabled'
            " ariaLabel="{{dxp.localeTranslator.translate('$.REGISTRATION.CONTINUE_ARIA_LABEL')}}">
          </dxp-button>
          <dxp-button *ngIf="newRegistration" text="{{dxp.localeTranslator.translate('$.REGISTRATION.CONTINUE')}}" [state]="
              registrationForm.valid &&
              consentChecked &&
              this.user.technicalAssetId != '' &&
              !showLoadingIndicator
                ? 'enabled'
                : 'disabled'
            " ariaLabel="{{dxp.localeTranslator.translate('$.REGISTRATION.CONTINUE_ARIA_LABEL')}}">
          </dxp-button>
          <p class="sign-in-text">
            {{dxp.localeTranslator.translate("$.REGISTRATION.ALREADY_ACCOUNT")}}
          </p>
          <dxp-link (ctaClickEvent)="goBackToLogin()"
            text="{{dxp.localeTranslator.translate('$.REGISTRATION.SIGN_IN')}}"
            ariaLabel="{{dxp.localeTranslator.translate('$.REGISTRATION.SIGN_IN')}}" type="dxp-btn-custom-link">
          </dxp-link>
        </div>
      </form>
    </div>
    <ng-template #post_registration>
      <div *ngIf="successfullyRegistered">
        <div class="dxp-pb-6 success-sign">
          <dxp-image src="https://asset.mastercard.com/content/dam/dxp/dxp-portal/assets/images/success.svg" responsive="true" alt="success icon">
          </dxp-image>
        </div>
        <h3 class="legend">
          {{dxp.localeTranslator.translate("$.REGISTRATION.THANK_YOU")}}, {{user.firstName}}
        </h3>
        <div *ngIf="newRegistration; else internal">
          <p>{{dxp.localeTranslator.translate("$.REGISTRATION.EMAIL_NOTIFICATION")}}</p>
        </div>
        <ng-template #internal>
          <div>
            <p>{{dxp.localeTranslator.translate("$.REGISTRATION.EMAIL_NOTIFICATION_INTERNAL")}}</p>
          </div>
        </ng-template>
        <div class="nav-link-post">
          <p>{{dxp.localeTranslator.translate("$.REGISTRATION.GO_BACK_TO_LOGIN")}}</p>
          <dxp-link (ctaClickEvent)="goBackToLogin()"
            text="{{dxp.localeTranslator.translate('$.REGISTRATION.SIGN_IN')}}"
            ariaLabel="{{dxp.localeTranslator.translate('$.REGISTRATION.SIGN_IN')}}" type="dxp-btn-custom-link">
          </dxp-link>
        </div>
      </div>
      <ng-template #unsuccessRegistration>
        <p translate>{{dxp.localeTranslator.translate("$.REGISTRATION.SERVICE_FAILURE")}}</p>
      </ng-template>
    </ng-template>
  </div>
  <div class="dxp-col-sm-6 dxp-col-12 md-image-container">
    <dxp-image src="https://asset.mastercard.com/content/dam/dxp/dxp-portal/assets/images/circle-with-text.svg" responsive="true" alt="side image">
    </dxp-image>
  </div>
</div>
