import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared/common.service';

@Component({
  selector: 'dxp-portal-public-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  message = '';
  constructor(private readonly sharedService: CommonService,) { }

  ngOnInit(): void {
    this.showMessage();
  }

  showMessage() {
    this.message = atob(this.sharedService.getErrorCode());
  }

}
