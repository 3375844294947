import { Component, HostListener, OnInit } from '@angular/core';
import { ResetPasswordDataService } from './reset-password-data.service';
import { environment } from '../../environments/environment';
import { CommonService } from '../shared/common.service';
import { ActivatedRoute } from '@angular/router';
import dxp from '@mc-dxp/dxp-ui-core';


@Component({
  selector: 'dxp-portal-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  pwdSchema;
  userId = '';
  showMsg = false;
  errorCode = '';
  resetPasswordApiUrl = environment.DXP_PORTAL_RESET_PASSWORD_API;
  severity = 'warn';
  message = 'some';
  dxp = dxp;
  constructor(
    private readonly resetPwdService: ResetPasswordDataService,
    private readonly sharedService: CommonService,
    public route: ActivatedRoute
  ) {}
  @HostListener('resetPasswordSuccessful', ['$event'])
  success(event: any) { // NOSONAR
    if (event.detail.response.Errors) {
      this.showMessage('error', this.dxp.localeTranslator.translate('$.RESETPASSWORD.PASSWORD_REP_FAILURE')); // NOSONAR
    } else {
      switch (event.detail.response.status?.toLowerCase()) { // NOSONAR
        case 'password reset successfully':
          this.performLogIn(event);
          break;
        case 'acct_locked':
          this.showMessage('error', this.dxp.localeTranslator.translate('$.LOGIN.ACCOUNT_LOCKED')); // NOSONAR
          break;
        case 'error':
        case 'passwd_rep_failure':
          this.showMessage('error', this.dxp.localeTranslator.translate('$.RESETPASSWORD.PASSWORD_REP_FAILURE')); // NOSONAR
          break;
        case 'acct_inactivated':
          this.showMessage('error', this.dxp.localeTranslator.translate('$.LOGIN.ACCOUNT_INACTIVATED'));
          break;
        default:
          break;
      }
    }
  }

  async ngOnInit() {
    this.fetchError();
    this.resetPwdService.getResetPasswordSchema().subscribe(
      (data) => {
        this.pwdSchema = data;
      },
      (error) => {
        console.log('err=status==>', error);
        console.log('err=msg==>', error.statusText);
      }
    );
  }

  fetchError() {
    this.errorCode = this.getErrorCode();
    switch (this.errorCode) {
      case 'passwd_rep_failure':
        this.showMessage('error', this.dxp.localeTranslator.translate('$.RESETPASSWORD.PASSWORD_REP_FAILURE')); // nosonar
        break;
      case 'passwd_warn':
      case 'passwd_warn_failure':
        this.showMessage('warn', this.dxp.localeTranslator.translate('$.RESETPASSWORD.PASSWORD_WARN'));
        break;
      case 'passwd_exp':
        this.showMessage('info', this.dxp.localeTranslator.translate('$.RESETPASSWORD.PASSWORD_EXPIRE'));
        break;
    }
  }
  getErrorCode() {
    return this.route.snapshot.queryParamMap.get('HERACLES_OP');
  }
  showMessage(type, message) {
    this.showMsg = true;
    this.severity = type;
    this.message = message;
  }

  performLogIn(event): void {
    this.sharedService.performLogout().subscribe(() => {
      this.sharedService.performLogIn(this.sharedService.getId(),
        event.detail.heraclesFormData?.confirmPassword).subscribe((response) => {
          if (response.heracles_result_code === '10000') {
            const url = `${location.origin}${environment.DXP_PORTAL_SECURED}portal`;
            this.sharedService.navigateByUrl(encodeURIComponent(url));
          }
        });
    });
  }

}
