import {
  Component,
  HostListener,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { CommonService } from '../shared/common.service';
import dxp from '@mc-dxp/dxp-ui-core';

const LOGIN_ACCOUNT_LOGIN = '$.LOGIN.ACCOUNT_LOGIN';
@Component({
  selector: 'dxp-portal-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('authLogin', { read: ElementRef }) authLogin: ElementRef;
  @ViewChild('dxpAuthentication', { read: ElementRef }) dxpAuthentication: ElementRef;
  registrationSchema: any;
  loginApiUrl = environment.DXP_PORTAL_LOGIN_API;
  logoutApiUrl = environment.DXP_PORTAL_LOGOUT_API;
  forgotPasswordApi = environment.DXP_PORTAL_FORGOT_PASSWORD_API;
  nuCaptchaBaseUrl=environment.DXP_PORTAL_NUCAPTCHA_BASE_URL;
  nuCaptchaClientId=environment.DXP_PORTAL_NUCAPTCHA_CLIENT_ID;
  testMode=environment.DXP_PORTAL_NUCAPTCHA_TEST_MODE;
  modalFlag = true;
  forgotFlag = true;
  errorMsg = '';
  passwordErrorMsg = '';
  message = '';
  errorCode = '';
  redirectionUrl = '';
  forgotModalTitle = '';
  resetPasswordRoute = '/reset-password';
  dxp = dxp;
  constructor(
    public router: Router,
    private readonly sharedService: CommonService,
    public accessPendingModal: ElementRef,
    public modalLogin: ElementRef,
    public route: ActivatedRoute
  ) {}

  @HostListener('textValue', ['$event'])
  loginTextValue(event) {
    if (event.target.innerText.localeCompare('*User ID') === 0) {
      this.sharedService.setId(event.detail.value);
    }
  }
  @HostListener('forgetPasswordSuccess', ['$event'])
  resetLinkSent() {
    this.passwordErrorMsg = '';
  }
  @HostListener('signInSuccessful', ['$event'])
  navigateTo(event: any) {
    let url;
    switch (event.detail.heracles_result_code) {
      case '10000':
        url = this.redirectionUrl
          ? `${location.origin}${this.redirectionUrl}`
          : `${location.origin}${environment.DXP_PORTAL_SECURED}portal`;
        this.sharedService.navigateByUrl(encodeURIComponent(url));
        break;
      case '10001':
        this.errorMsg = this.dxp.localeTranslator.translate('$.FORGOT_MODEL.API_ERROR');
        break;
      case '11005':
        this.router.navigate([this.resetPasswordRoute], {
          queryParams: { HERACLES_OP: 'passwd_warn' }
        });
        break;
      case 'passwd_warn_failure':
        this.router.navigate([this.resetPasswordRoute], {
          queryParams: { HERACLES_OP: 'passwd_warn_failure' },
        });
        break;
      case '14002':
        this.forgotFlag = true;
        this.dxpAuthentication.nativeElement.setCurrentStep('forgot-password');
        this.forgotClicked();
        this.forgotModalTitle = this.dxp.localeTranslator.translate('$.FORGOT_MODEL.EXPIRED');
        this.passwordErrorMsg = this.dxp.localeTranslator.translate('$.RESETPASSWORD.PASSWORD_EXPIRE');
        break;
      case '14004':
      case '14003':
        this.errorMsg = this.dxp.localeTranslator.translate(LOGIN_ACCOUNT_LOGIN);
        break;
      case '14000':
        this.errorMsg = this.dxp.localeTranslator.translate(LOGIN_ACCOUNT_LOGIN);
        const errorTitle: any = this.modalLogin.nativeElement.querySelector(
          '.dxp-modal-body .dxp-error'
        );
        errorTitle.focus();
        break;
      case 'error':
      default:
        this.errorMsg = '';
        break;
    }
  }
  @HostListener('signInFailed', ['$event'])
  showError(){
    this.errorMsg = this.dxp.localeTranslator.translate(LOGIN_ACCOUNT_LOGIN);
  }
  ngOnInit() {
    if (this.sharedService.navigatedFromRegistration) {
      this.loginModal('_registration');
      this.sharedService.navigatedFromRegistration = false;
    }
    this.showMessage();
    this.redirectionUrl = this.getRedirectionPage();
    const headerElement: Element = document.getElementsByTagName('dxp-header-rich')[0];
    if (headerElement) {
      headerElement.setAttribute('background-type', 'transparent');
    }
    this.forgotModalTitle = this.dxp.localeTranslator.translate('$.FORGOT_MODEL.TITLE');
  }

  loginModal(data: any): void {
    if (
      data.target?.classList.contains('login-button') ||
      data === '_registration'
    ) {
      this.modalFlag = true;
      const dxpModal =
        this.modalLogin.nativeElement.querySelector('.signin-model');
      dxpModal.openModal();
    } else {
      this.router.navigate(['register']);
    }
  }

  modalclosed() {
    this.errorMsg = '';
    this.modalFlag = false;
    this.forgotFlag = true;
  }
  forgotClicked() {
    this.errorMsg = '';
    this.passwordErrorMsg = '';
    this.forgotFlag = !this.forgotFlag;
    this.forgotModalTitle = this.dxp.localeTranslator.translate('$.FORGOT_MODEL.TITLE');
    const dxpModal = this.modalLogin.nativeElement.querySelector('dxp-modal');
    const ariaLabel = this.forgotFlag
      ? this.dxp.localeTranslator.translate('$.LOGIN.MODEL_ACCESSIBILITY_TEXT')
      : this.dxp.localeTranslator.translate(
          '$.FORGOT_MODEL.MODEL_ACCESSIBILITY_TEXT'
        );
    dxpModal.setAttribute('aria-label', ariaLabel);
    setTimeout(() => {
      this.authLogin?.nativeElement.setCaptcha('arithmetic'); // nosonar
    }, 100);
  }
  doLogOut() {
    this.sharedService.performLogout().subscribe(
      () => {
        localStorage.removeItem('userId');
        localStorage.removeItem('session');
      },
      (error) => {
        console.log(error);
      }
    );
  }
  performLogout() {
    this.doLogOut();
  }
  showMessage() {
    this.message = atob(this.sharedService.getErrorCode());
  }
  getRedirectionPage() {
    return this.route.snapshot.queryParamMap.get('redirect')
      ? atob(this.route.snapshot.queryParamMap.get('redirect'))
      : '';
  }
}
