import { Component } from '@angular/core';
import dxp from '@mc-dxp/dxp-ui-core';

@Component({
  selector: 'dxp-portal-public-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  dxp = dxp;
  constructor() {
    // do nothing.
  }
}
