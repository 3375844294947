<div class="public-header">
  <dxp-header-rich data-theme="dxp-theme-black" background-type="transparent" sticky="true">
    <dxp-logo src="https://asset.mastercard.com/content/dam/dxp/dxp-demo-assets/images/logos/mclogo-for-footer.svg"
      src-sm="https://asset.mastercard.com/content/dam/dxp/dxp-demo-assets/images/logos/mclogo-for-footer.svg"
      logoTitle="{{dxp.localeTranslator.translate('$.HEADER.TEXT')}}" href="/login"
      alt="{{dxp.localeTranslator.translate('$.HEADER.TEXT')}}" slot="logo"
      ariaLabel="{{dxp.localeTranslator.translate('$.HEADER.TEXT')}}">
    </dxp-logo>
    <!-- place this cta link in the slot of language selector in the header-rich -->
    <dxp-link slot="languageSelector" text="Help" alt="help icon"
      icon-align="left" src="https://asset.mastercard.com/content/dam/dxp/dxp-portal/assets/images/help_icon.svg" 
      open-in-new-tab="true" (ctaClickEvent)= "openUserGuide()">
    </dxp-link>
  </dxp-header-rich>
</div>